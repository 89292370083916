import React from 'react';
import Helmet from 'react-helmet';

const Head = () => (
  <Helmet>
    <html lang="de" />
    <meta
      name="description"
      content="Unser rund 110 m² großer Eventspace bietet genügend Raum für Workshops, Seminare, Schulungen und Meeting für bis zu 100 Leute."
    />
    <meta name="language" content="de" />
    <meta name="robots" content="index, follow" />^
  </Helmet>
);

export default Head;
