import React from 'react';
import { Link } from 'gatsby';

const linkHover = 'hover:text-royal-blue';
const externalLinkAttributes = {
  target: '_blank',
  rel: 'noreferrer',
  className: linkHover
};

const Footer = () => (
  <footer className="bg-champagne-blue py-12 md:pb-20 lg:text-xl">
    <div className="container grid gap-6 md:grid-cols-3">
      <p>
        &copy;{' '}
        <a href="https://codecamp-n.com/" {...externalLinkAttributes} title="Zur CodeCamp:N-Website">
          CodeCamp:N GmbH
        </a>
      </p>
      <ul className="space-y-3">
        <li>
          <a href="mailto:events@codecamp-n.com" className={linkHover} title="E-Mail an events@codecamp-n.com verfassen">
            events@codecamp-n.com
          </a>
        </li>
        <li>
          <a href="tel:+491713309546" className={linkHover} title="Bei CodeCamp:N anrufen">
            +49 (0)171 3309546
          </a>
        </li>
      </ul>
      <ul className="space-y-3">
        <li>
          <a
            href="https://codecamp-n.com/impressum/"
            {...externalLinkAttributes}
          >
            Impressum
          </a>
        </li>
        <li>
          <Link to="/datenschutz" className={linkHover}>
            Datenschutz
          </Link>
        </li><li>
          <a href="/AGB für eventspace.pdf" className={linkHover}>
            AGB
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
